import React, { useState } from 'react'
import "../form.css"
import { v4 as uuidv4 } from 'uuid'

export default function Form(props) {

  const [input, setInput] = useState ('');
  const manageChange = e => {
    setInput(e.target.value);
  }

  const manageSend = e => {
    e.preventDefault();

    const newTask = {
      id: uuidv4() ,
      text: input,
      completeTask: false
    }

    props.onSubmit(newTask);
  }



  return (
    <form 
      className='task-form'
      onSubmit={manageSend}>
        <input
            className='task-input'
            type='text'
            placeholder='your task...'
            name='text'
            onChange={manageChange}
        />
        <button className='task-button'>
            Add task
        </button>

    </form>
  )
}
