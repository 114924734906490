import React, { useState } from 'react'
import Form from './Form'
import '../todolist.css'
import Task from './Task';

export default function Todolist() {

    const[tasks, setTask] = useState([]);

    const newTask = task => {
        console.log(task)
        if (task.text.trim()) {
            task.text = task.text.trim();
            const tasksUpdated = [task, ...tasks];
            setTask(tasksUpdated);
        }
    }

    const deleteTask = id => {
        const tasksUpdated = tasks.filter(task => task.id !== id);
        setTask(tasksUpdated);
    }

    const completeTask = id => {
        const tasksUpdated = tasks.map(task => {
            if(task.id === id) {
                task.done = !task.done
            }

            return task;
        });
        setTask(tasksUpdated)
    }
  return (
    <>
        <Form onSubmit={newTask} />
        <div className='todolist-container'>
            {
                tasks.map((task) =>
                <Task 
                    key={task.id}
                    id={task.id}
                    text={task.text}
                    done={task.done}
                    completeTask={completeTask}
                    deleteTask={deleteTask}
                />
                )
            }
        </div>
    </>
  )
}
