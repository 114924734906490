import React from 'react'
import Task from './Task'
import Form from './Form'
import Todolist from './Todolist'

export default function Todo() {
return (
    <div>
        <div className = 'main-container'>
        <h1 className='title'>To Do List</h1>
        <Todolist />

        </div>
    </div>
)
}
