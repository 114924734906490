import React from 'react'
import '../task.css'
import { AiFillCloseCircle } from "react-icons/ai";




export default function Task( {id, text,done, completeTask, deleteTask} ) {
  return (
    <div className= { done ? 'task-container done' : 'task-container' }>
        <div 
        className='text-task'
        onClick={()=> completeTask(id)}
        >
            { text }
        </div>
        <div 
        className='task-icon-container' 
        onClick={()=> deleteTask(id)}> 
          <AiFillCloseCircle className='task-icon' />
        </div>
    </div>
  )
}
