import './App.css';
import Logo from './components/Logo';
import Todo from './components/Todo';
import Task from './components/Task';

function App() {
  return (
    <div className="App">
      <div className='main'>
      <Logo />
      <Todo />
      
      </div>
      
    </div>
  );
}

export default App;
